.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.hide-scrollbar {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0px; /* For Chrome, Safari, and Opera */
}

table,
th,
td {
  border: 1px solid rgb(202, 194, 194);
  border-collapse: collapse;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #b7b7b7;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b7b7b7;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b7b7b7;
}
img {
  max-width: none;
}

:root {
  --fc-list-event-dot-width: 10px;

  /* calendar buttons color */
  --fc-button-text-color: #fff;
  --fc-button-bg-color: #01464f;
  --fc-button-border-color: #01464f;
  --fc-button-hover-bg-color: #01464f;
  --fc-button-hover-border-color: #01464f;
  --fc-button-active-bg-color: #01464f;
  --fc-button-active-border-color: #01464f;

  --fc-small-font-size: 10px;
  --fc-page-bg-color: #fff;
  --fc-neutral-bg-color: rgba(208, 208, 208, 0.3);
  --fc-neutral-text-color: #808080;
  --fc-border-color: #ddd;

  --fc-event-bg-color: #01464f;
  --fc-event-border-color: #01464f;
  --fc-event-text-color: #fff;
  --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);

  --fc-more-link-bg-color: #d0d0d0;
  --fc-more-link-text-color: inherit;

  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;

  --fc-non-business-color: rgba(215, 215, 215, 0.3);
  --fc-bg-event-color: rgb(143, 223, 130);
  --fc-bg-event-opacity: 0.3;
  --fc-highlight-color: rgba(188, 232, 241, 0.3);
  --fc-today-bg-color: #e1fbff;
  --fc-now-indicator-color: #808080;
}
.fc-event {
  font-size: 13px;
}
.swal2-title {
  font-size: 16px !important;
}
[type="text"]:focus {
  --tw-ring-color: none !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"]::-moz-inner-spin-button,
input[type="number"]::-moz-outer-spin-button {
  appearance: none;
  margin: 0;
}

/* Hide arrows for IE */
input[type="number"]::-ms-clear {
  display: none;
}

.fixTableHead {
  overflow-y: auto;
}
.fixTableHead thead th {
  position: sticky;
  top: 0;
}
table {
  border-collapse: collapse;
  width: 100%;
}
th,
th {
  background: #e3e8ef;
}
